<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Embedded & IoT Systems Domains" />
        <EmbeddedDomain/>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import EmbeddedDomain from '../Domains/EmbeddedDomain'

import Footer from '../Layout/Footer'

export default {
    name: 'EmbeddedDomainPage',
    components: {
        Navbar,
        PageTitle,
        EmbeddedDomain,
        Footer,
    }
}
</script>