<template>
    <div>
        <Navbar  />
        <PageTitle pageTitle="Testing Services" />
        <TestingServices/>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import TestingServices from '../Services/TestingServices'
import Footer from '../Layout/Footer'

export default {
    name: 'TestingServicePage',
    components: {
        Navbar,
        PageTitle,
        TestingServices,
        Footer,
    }
}
</script>