<template>
    <div class="header header-bg border-bottom-1">
        <div class="container-fluid p-lg-0 custom-container-fluid">
            <div class="row m-lg-0 align-items-center">
                <div class="col-lg-6 p-lg-0 order-lg-2">
                    <div class="max-585 me-lg-auto">
                        <div class="header-content">
                            <h1>Software Solutions Provider</h1>
                            <p>Specialist in solutions for Machine Learning, Big Data and Artificial Intelligence. </p>
                            <p> We also have many years of experience in IoT, Embedded and Cloud Development</p>
                            <div class="button-group button-group-animated">
                                <router-link to="/about-us" class="btn main-btn">
                                    Learn About Us
                                </router-link>
                                <router-link to="/contact" class="btn main-btn main-btn-white">
                                    Get Started
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 p-lg-0 order-lg-1">
                    <div class="header-content-image text-center">
                        <img src="../../assets/images/homepage-two/header-shape.png" alt="header">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>