<template>
    <div>
        <Navbar class="bg-white" />
        <PageTitle pageTitle="Search Page" />
        <Search />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Search from '../Search/Search'

import Footer from '../Layout/Footer'

export default {
    name: 'SearchPage',
    components: {
        Navbar,
        PageTitle,
        Search,

        Footer,
    }
}
</script>