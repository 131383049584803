<template>
    <div>
        <Navbar class="bg-white" />
        <PageTitle pageTitle="Our Team" />
        <Team />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Team from '../Team/Team'

import Footer from '../Layout/Footer'

export default {
    name: 'TeamPage',
    components: {
        Navbar,
        PageTitle,
        Team,

        Footer,
    }
}
</script>