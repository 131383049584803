import { createWebHistory, createRouter } from "vue-router";

// All Pages
import HomePageTwo from "../components/Pages/HomePageTwo";
import AboutPage from "../components/Pages/AboutPage";
import ServicesPage from "../components/Pages/ServicesPage";

import EmbeddedService from "../components/Pages/EmbeddedDomainPage";
import TelecomService from "../components/Pages/TelecomDomainPage";
import CloudSolutions from "../components/Pages/CloudDomainPage";
import DataMining from "../components/Pages/DataMiningDomainPage";
import MachineLearning from "../components/Pages/MachineLearningDomainPage";

import TestingServices from "../components/Pages/TestingServicePage";
import ArchitectureServices from "../components/Pages/ArchitectureServicePage";
import DevelopmentServices from "../components/Pages/DevelopmentServicePage";

import FaqPage from "../components/Pages/FaqPage";
import TeamPage from "../components/Pages/TeamPage";
import ProjectsPage from "../components/Pages/ProjectsPage";
import SingleProjectsPage from "../components/Pages/SingleProjectsPage";
import CasesStudyPage from "../components/Pages/CasesStudyPage";
import CasesStudyTwoPage from "../components/Pages/CasesStudyTwoPage";
import SingleCasesStudyPage from "../components/Pages/SingleCasesStudyPage";
// import TestimonialPage from "../components/Pages/TestimonialPage";
import OurClientsPage from "../components/Pages/OurClientsPage";
// import CareersPage from "../components/Pages/CareersPage";
// import ProductsPage from "../components/Pages/ProductsPage";
// import SingleProductsPage from "../components/Pages/SingleProductsPage";
// import CartPage from "../components/Pages/CartPage";
// import CheckoutPage from "../components/Pages/CheckoutPage";
// import AuthenticationPage from "../components/Pages/AuthenticationPage";
// import ForgetPasswordPage from "../components/Pages/ForgetPasswordPage";
// import MyAccountPage from "../components/Pages/MyAccountPage";
// import MyOrdersPage from "../components/Pages/MyOrdersPage";
// import MyAddressesPage from "../components/Pages/MyAddressesPage";
// import DemoProductPage from "../components/Pages/DemoProductPage";
import FeedbackPage from "../components/Pages/FeedbackPage";
// import TermsOfServicePage from "../components/Pages/TermsOfServicePage";
// import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import SearchPage from "../components/Pages/SearchPage";
import ErrorPage from "../components/Pages/ErrorPage";
import ComingSoonPage from "../components/Pages/ComingSoonPage";

// import BlogPage from "../components/Pages/BlogPage";
// import BlogRightSidebarPage from "../components/Pages/BlogRightSidebarPage";
// import SingleBlogPage from "../components/Pages/SingleBlogPage";
import ContactPage from "../components/Pages/ContactPage";

const routes = [
  { path: "/", component: HomePageTwo },
  { path: "/about-us", component: AboutPage },
  { path: "/services", component: ServicesPage },
  { path: "/embedded-domain", component: EmbeddedService },
  { path: "/telecom-domain", component: TelecomService },
  { path: "/cloud-domain", component: CloudSolutions },
  { path: "/machinelearning-domain", component: MachineLearning },
  { path: "/datamining-domain", component: DataMining },
  { path: "/testing-services", component: TestingServices },
  { path: "/architecture-services", component: ArchitectureServices },
  { path: "/development-services", component: DevelopmentServices },

  { path: "/faqs", component: FaqPage },
  { path: "/our-team", component: TeamPage },
  { path: "/projects", component: ProjectsPage },
  { path: "/single-project", component: SingleProjectsPage },
  { path: "/cases", component: CasesStudyPage },
  { path: "/cases-two-columns", component: CasesStudyTwoPage },
  { path: "/single-case", component: SingleCasesStudyPage },
  // { path: "/testimonial", component: TestimonialPage },
  { path: "/our-clients", component: OurClientsPage },
  // { path: "/careers", component: CareersPage },
  // { path: "/products", component: ProductsPage },
  // { path: "/single-product", component: SingleProductsPage },
  // { path: "/cart", component: CartPage },
  // { path: "/checkout", component: CheckoutPage },
  // { path: "/authentication", component: AuthenticationPage },
  // { path: "/forget-password", component: ForgetPasswordPage },
  // { path: "/my-account", component: MyAccountPage },
  // { path: "/my-orders", component: MyOrdersPage },
  // { path: "/my-addresses", component: MyAddressesPage },
  // { path: "/demo-product", component: DemoProductPage },
  { path: "/feedback", component: FeedbackPage },
  // { path: "/terms-of-service", component: TermsOfServicePage },
  // { path: "/privacy-policy", component: PrivacyPolicyPage },
  { path: "/search-page", component: SearchPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage },
  { path: "/coming-soon", component: ComingSoonPage },
  // { path: "/blogs", component: BlogPage },
  // { path: "/blogs-right-sidebar", component: BlogRightSidebarPage },
  // { path: "/single-blog", component: SingleBlogPage },
  { path: "/contact", component: ContactPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
