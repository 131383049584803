<template>
    <div>
        <Navbar  />
        <PageTitle pageTitle="Development Services" />
        <DevelopmentServices/>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import DevelopmentServices from '../Services/DevelopmentServices'
import Footer from '../Layout/Footer'

export default {
    name: 'DevelopmentServicePage',
    components: {
        Navbar,
        PageTitle,
        DevelopmentServices,
        Footer,
    }
}
</script>