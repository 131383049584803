<template>
    <div class="about-section bg-off-white pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-image text-center">
                        <img src="../../assets/images/feature-shape/feature-shape-1.png" alt="shape">
                    </div>
                </div>
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-details">
                        <div class="section-title section-title-left text-start">
                            <small>About Us</small>
                            <h2>Software Solutions </h2>
                        </div>
                        <div class="about-content">
                            <p>We are software solutions provider  with 300+ developers working in the AI, Cloud or Embedded/IoT streams. 
                                Our Motto is to help company achieve thier Software Solutions and provide services in architecture, development or testing.
                            .</p>
                            <ul>
                                <li>Architecture Services</li>
                                <li>Development Services</li>
                                <li>Testing Services</li>
                                <li>Compliance Services</li>
                            </ul>
                            
                            <!-- <router-link to="/about-us" class="btn main-btn">About Us</router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>