<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="About Us" />
        <About />
        <WhyChooseUs />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import About from '../Common/About'
import WhyChooseUs from '../About/WhyChooseUs'
import Footer from '../Layout/Footer'

export default {
    name: 'AboutPage',
    components: {
        Navbar,
        PageTitle,
        About,
        WhyChooseUs,
        Footer,
    }
}
</script>