<template>
    <div>
        <Navbar class="bg-white" />
        <PageTitle pageTitle="Telecom Domain" />
        <TelecomDomain/>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import TelecomDomain from '../Domains/TelecomDomain'

import Footer from '../Layout/Footer'

export default {
    name: 'TelecomDomainPage',
    components: {
        Navbar,
        PageTitle,
        TelecomDomain,
        Footer,
    }
}
</script>