<template>
    <div>
        <Navbar class="bg-white" />
        <PageTitle pageTitle="Single Project" />
        <ProjectsDetails />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import ProjectsDetails from '../SingleProjects/ProjectsDetails'

import Footer from '../Layout/Footer'

export default {
    name: 'SingleProjectsPage',
    components: {
        Navbar,
        PageTitle,
        ProjectsDetails,

        Footer,
    }
}
</script>