<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/service-1.png" alt="service">
                        <h2>Cloud Systems</h2>
                            <p>Traditional inhouse server now run in the cloud systems. Cloud provides best mean to optimise development, testing and deployment</p>
                            <p>Our team can provide the services that meets your organisation requirements</p>

                        <h3>Platform Development</h3>
                        <p>Cloud platform provides a virtual or dedicated hardware to perform the functionality to host your solutions.</p>
                        <p>Hybrid solutions are popoular choice where functionality is divided in Premise or Cloud solution</p>
                        <p>Migration of legacy solution to Cloud solutions</p>
                        <p>Our team can helps your devices to run on :</p>
                        <ul>
                            <li>AWS EC2</li>
                            <li>GCP Cloud Compute Engine</li>
                            <li>Azure Virtual Machine</li>
                        </ul>

                        <h3>Serverless Development</h3>
                        <p>Serverless development optimizes using cloud solution or database solution. It helps in breaking functionality and make it more event driven</p>
                        <p>It can save companies lot of money instead of hosting a solution.</p>
                        
                        <h3>Security</h3>
                        <p>Security is very important in the cloud. We can implement or advise the best optimal security practise..</p>
                        <ul>
                            <li>IAM policies</li>
                            <li>Deployment Security</li>
                            <li>Key Storage</li>
                        </ul>

                        <h3>Data Migration</h3>
                        <p>Data that needs to be moved to the Cloud or keeping data in between premise and cloud can all be supported by our team.</p>
                        <p>Our team can work with you on best architecture practise and optimised solution for the data between sites or moving data to cloud.</p>


                        <h3>Deployment</h3>
                        <p>Cloud solutions need a strategy for development, testing and production deployment. We can support configuration for Infrastructre as Code, manage deployment and configuration using IAAS or Terraform.</p>

                        <h3>Data Compliance</h3>
                        <p>Data Compliance is a challenge considering various different regions hold different restirction. We provide compliance according to the region..</p>

                        <!-- <div class="row">
                            <div class="col-12 col-sm-6">
                                <img src="../../assets/images/services/service-details-1.jpg" alt="service">
                            </div>
                            <div class="col-12 col-sm-6">
                                <img src="../../assets/images/services/service-details-2.jpg" alt="service">
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-4 pb-30">
                    <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="sidebar-item sidebar-bg">
                        <h3>Focus Areas</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/embedded-domain" class="current">Embedded & IoT Domain</router-link>
                            </li>
                            <li>
                                <router-link to="/telecom-domain">Telecom Domain</router-link>
                            </li>
                            <li>
                                <router-link to="/cloud-domain">Cloud Domain</router-link>
                            </li>
                            <li>
                                <router-link to="/datamining-domain">Data Mining</router-link>
                            </li>
                            <li>
                                <router-link to="/machinelearning-domain">Machine Learning</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/architecture-services" class="current">Architecture</router-link>
                            </li>
                            <li>
                                <router-link to="/development-services">Development</router-link>
                            </li>
                            <li>
                                <router-link to="/testing-services">Testing</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CloudDomain'
}
</script>