<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>Services</small>
                <h2>Our Purpose Is To Deliver Excellence <br> In Service And Execution</h2>
                <!-- <p>Our purpose is to deliver excellence in service and execution.</p> -->
            </div>
            <div class="row">
                
                <router-link tag="div" to="/architecture-services" class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-diagram"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Architecture</h3>
                            <p>Architecture and finding the right optimum solution for your requirements.</p>
                            <router-link to="/architecture-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </div>
                </router-link>
                <router-link tag="div" to="/development-services" class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-rocket"></i>
                            <!-- <img src="../../assets/images/cases/case-lg-6.jpg" alt="shape"> -->

                        </div>
                        <div class="service-card-body">
                            <h3>Development</h3>
                            <p>Development of the solution as per your requirements and timeline.</p>
                            <router-link to="/development-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </div>
                </router-link>
                <router-link tag="div" to="/testing-services" class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-automation"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Testing</h3>
                            <p>Automtation testing or black box testing for either cloud or edge device.</p>
                            <router-link to="/testing-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </div>
                </router-link>
                
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>