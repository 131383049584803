<template>
    <div class="contact-info-section pt-100 pb-100">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>Contact Information</small>
                <h2>Let's Talk To Find Right Solution</h2>
                <p>Our team will be happy to learn about your requirements and find the right solution for your company. Please contact us.</p>
            </div>
            <!-- Contact-info -->
            <div class="contact-info mb-15">
                <div class="row">
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-location-pin"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Location</h3>
                                    <p>Visit Our Office</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>20 Greville Avenue</p>
                                <p>South Croydon, CR2 8NL</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-ui-call"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Make A Call</h3>
                                    <p>Let’s Talk with our Experts</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>
                                    <a href="/tel:00442045099145">+44 20 4509 9145</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30 offset-lg-0 offset-md-3">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-email"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Send An Email</h3>
                                    <p>Don’t Hesitate to Email</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>
                                    <a href="/mailto:info@oasislimited.co.uk">info@oasislimited.co.uk</a>
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Contact-info -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactInfo'
}
</script>