<template>
    <div>
        <Navbar />
        <MainBanner />
        <Process />
        <Services />
        <WhyChooseUs/>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBanner from '../HomeTwo/MainBanner'
import Process from '../HomeTwo/Process'
import Services from '../Common/Services'
import Footer from '../Layout/Footer'
import WhyChooseUs from '../About/WhyChooseUs.vue'

export default {
    name: 'HomePageTwo',
    components: {
        Navbar,
        MainBanner,
        Process,
        Services,
        WhyChooseUs,
        Footer,
    }
}
</script>