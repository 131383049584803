<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/service-1.png" alt="service">
                        <h2>Embedded & IoT Systems</h2>
                        <p>Embedded Systems and IoT devices require working at device driver level, understand the system kernel, HAL APIs, Manufacturer provided APIs or Vendor APIs.</p>
                        <p>Our team has worked has on various differnet need of client and we can provide you the same service.</p>
                        <p>We can deliver wither full product or be part of your team in development of certain features or testing of the product.</p>
                        <h3>Medical Devices</h3>
                        <p>Medical devices support as capturing from sensor or using AV to detect anomalies.</p>
                        
                        <h3>IP Camera</h3>
                        <p>IP based camera for security survileance and working with different aspects such as</p>
                        <ul>
                            <li>Linux Drivers</li>
                            <li>Hardware Abstraction Layer (HAL)</li>
                            <li>Video capture from sensor and ISP handling</li>
                            <li>Audio capture from ALSA</li>
                            <li>Video Encoding support: H264/H265/VP8/VP9/AV1</li>
                            <li>Audio Encoding support: AAC</li>
                            <li>Streaming support via WebRTC or RTSP</li>
                       
                        </ul>
                        
                        <h3>Autosar (AUTomotive Open System ARchitecture)</h3>
                        <p>Cars standard architecture experience handling security and communication with different components in the car system.</p>
                        <p>Our partners has been working for more than 10 years with various aspects in development and making cars compliant to ISO26262 and Auto SPICE compliant.</p>

                        <h3>Audio Video Devices</h3>
                        <p>Audio Video devices has challenges for streaming, self-managed devices and display runnning on different hardware.</p>
                        <p>Devices more and more are cloud connected and we have experience helping you in adapting to MPEG-DASH or HLS to stream content to your devices.</p>

                        <h3>IoT Protocol</h3>
                        <p>IoT devices support variety of protocols: MQTT or CoAP and need to be optimized.</p>
                        <p>Our teams can help your with integrating different protocols as per your device requirements</p>

                        <h3>Compliance Testing</h3>
                        <p>Compliance testing are the requirement for the embedded or IoT devices.</p>

                        <!-- <div class="row">
                            <div class="col-12 col-sm-6">
                                <img src="../../assets/images/services/service-details-1.jpg" alt="service">
                            </div>
                            <div class="col-12 col-sm-6">
                                <img src="../../assets/images/services/service-details-2.jpg" alt="service">
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-4 pb-30">
                    <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="sidebar-item sidebar-bg">
                        <h3>Focus Areas</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/embedded-domain" class="current">Embedded & IoT Domain</router-link>
                            </li>
                            <li>
                                <router-link to="/telecom-domain">Telecom Domain</router-link>
                            </li>
                            <li>
                                <router-link to="/cloud-domain">Cloud Domain</router-link>
                            </li>
                            <li>
                                <router-link to="/datamining-domain">Data Mining</router-link>
                            </li>
                            <li>
                                <router-link to="/machinelearning-domain">Machine Learning</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/architecture-services" class="current">Architecture</router-link>
                            </li>
                            <li>
                                <router-link to="/development-services">Development</router-link>
                            </li>
                            <li>
                                <router-link to="/testing-services">Testing</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmbeddedDomain'
}
</script>