<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Architecture Services" />
        <ArchitectureServices/>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import ArchitectureServices from '../Services/ArchitectureServices'
import Footer from '../Layout/Footer'

export default {
    name: 'ServicesPage',
    components: {
        Navbar,
        PageTitle,
        ArchitectureServices,
        Footer,
    }
}
</script>