<template>
    <div class="process-section pt-100 pb-70">
        <div class="container">
            <div class="process-content">
                <div class="row justify-content-center">
                    <router-link class="col-md-6 col-lg-4 pb-30" to="/machinelearning-domain" tag="div">
                        <div class="service-card process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-four.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Machine Learning</h3>
                                <p> We specialise in developing AI solution and train machine learning models for the Edge or Cloud Devices.</p>
                            </div>
                            <router-link to="/machinelearning-domain" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </router-link>
                    <router-link class="col-md-6 col-lg-4 pb-30" to="/datamining-domain" tag="div">
                        <div class="service-card process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-one.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Data Mining</h3>
                                <p> We specialise in the data mining and creating the data dashboards. We also provide solutions for the ingestion of the data.</p>
                            </div>
                            <router-link to="/datamining-domain" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </router-link>                  
                    <router-link class="col-md-6 col-lg-4 pb-30" to="/cloud-domain" tag="div">
                        <div class="service-card process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/cloud.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Cloud Solutions</h3>
                                <p>We specialise in architecture, development or testing of the Cloud solutions - AWS, GCP or Azure. We also support migration to the cloud platform.</p>
                            </div>   
                            <router-link to="/cloud-domain" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </router-link>
                    <router-link class="col-md-6 col-lg-4 pb-30" to="/embedded-domain" tag="div" >
                        <div class="service-card process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-two.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Embedded & IoT Systems</h3>
                                <p>We specialise in architecture, development and testing of the embedded software. IoT devices, Camera, Cars or Medical Equipment </p>
                            </div>
                            <router-link to="/embedded-domain" class="redirect-link" >
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </router-link>
                    <router-link class="col-md-6 col-lg-4 pb-30" to="/telecom-domain" tag="div">
                        <div class="service-card process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-two.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Telecom</h3>
                                <p>We specialise in architecture, development and testing of the 5G solution. Moving the telecom solution to the cloud. </p>
                            </div>
                            <router-link to="/telecom-domain" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Process'
}
</script>