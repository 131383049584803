<template>
    <footer class="bg-off-white footer">
        <div class="footer-shapes">
            <div class="footer-shape">
                <img src="../../assets/images/shape-1.png" alt="shape">
            </div>
            <div class="footer-shape footer-round-shape">
                <img src="../../assets/images/shape-2.png" alt="shape">
            </div>
        </div>
        <div class="footer-upper pt-100 pb-80 position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-item">
                            <div class="footer-logo">
                                <router-link to="/">
                                    <img src="../../assets/images/logo1.png" alt="logo">
                                </router-link>
                            </div>
                            <div class="footer-details">
                                <p>Your Software Solution Partner</p>
                                <ul class="social-list social-list-btn">
                                    <li>
                                        <a href="https://www.linkedin.com/company/oasis-private-limtied" target="_blank">
                                            <i class="icofont-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i class="icofont-twitter"></i>
                                        </a>
                                    </li>
                                   
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul class="footer-details footer-list">
                                <li><router-link to="/about-us">About Us</router-link></li>
                                <li><router-link to="/blogs">News & Blogs</router-link></li>
                                <li><router-link to="/services">Services</router-link></li>
                                <li><router-link to="/contact">Contact</router-link></li>
                            </ul>
                        </div>
                    </div> -->
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Services</h3>
                            </div>
                            <ul class="footer-details footer-list">
                                <li><router-link to="/machinelearning-domain">Machine Learning</router-link></li>
                                <li><router-link to="/datamining-domain">Data Mining</router-link></li>
                                <li><router-link to="/cloud-domain">Cloud Solutions</router-link></li>
                                <li><router-link to="/embedded-domain">Embedded Systems</router-link></li>
                                <li><router-link to="/telecom-domain">Telecom</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Focus Areas</h3>
                            </div>
                            <ul class="footer-details footer-list">
                                <li><router-link to="/architecture-services">Architecture</router-link></li>
                                <li><router-link to="/develpment-services">Development</router-link></li>
                                <li><router-link to="/testsing-services">Testing</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Contact</h3>
                            </div>
                            <div class="footer-details footer-address">
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Phone:</h4>
                                        <p><a href="/tel:00442045099145">+44 20 4509 9145</a> </p>
                                        
                                    </div>
                                </div>
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Email:</h4>
                                        <p> <a href="/mailto:info@oasislimited.co.uk">info@oasislimited.co.uk</a></p>
                                    </div>
                                </div>
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Address:</h4>
                                        <p>20 Greville Avenue, South Croydon, CR2 8NL, UK</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>