<template>
    <div>
        <Navbar class="bg-white" />
        <PageTitle pageTitle="Our Cases" />
        <CasesStudy />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import CasesStudy from '../CasesStudyTwo/CasesStudy'

import Footer from '../Layout/Footer'

export default {
    name: 'CasesStudyTwoPage',
    components: {
        Navbar,
        PageTitle,
        CasesStudy,

        Footer,
    }
}
</script>